<template>
  <div class="guide-view__tab"
       v-on:click="toggle"
       :id="section.content.id">
    <Row justify="between"
         align="center"
         class="guide-view__tabs-header">
      <span class="guide-view__tabs-header-title">{{section.title}}</span>
      <Icon viewport="0 0 48 48"
            :xlink="opened ? 'minus' : 'plus'"/>
    </Row>
    <div v-if="opened">
      <transition name="fade">
        <div v-for="content in section.content"
             :key="content.id"
             class="guide-view__tabs-content">
          <div v-for="block in content.block"
               :key="block.id"
               class="guide-view__tabs-block">
            <p v-if="block.p"
               class="guide-view__tabs-content-title">{{block.p}}</p>
            <ul v-if="block.list"
                class="guide-view__tabs-content-text">
              <li v-for="item in block.list"
                  :key="item.id">{{item}}
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuideAccordion',
  props: {
    params: {
      section: {},
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    },
  },
  computed: {
    section() {
      return this?.params?.section;
    },
  },
};
</script>
